import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import pour accéder à Redux
import Modal from 'fast-modal-library';
import HidePasswordIcon from '../components/HidePasswordIcon';

const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$/;
const apiPrefix = process.env.REACT_APP_API_PREFIX;


const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();
    const { token } = useParams();

    // Récupération de l'appNumber depuis Redux
    const appNumber = useSelector((state) => state.app.appNumber);
    console.log(appNumber)
    useEffect(() => {
        const checkToken = async () => {
            try {
                const response = await fetch(apiPrefix + `reset/${token}?appNumber=${appNumber}`, {
                    method: 'GET',
                });
    
                if (response.status !== 200) {
                    console.error('Erreur lors de la validation du token:', await response.text());
                    navigate('/error');
                }
            } catch (error) {
                console.error('Erreur réseau lors de la validation du token:', error);
                navigate('/error');
            }
        };
    
        checkToken();
    }, [token, navigate, appNumber]);
    

    const hide = () => {
        setShowModal(false);
        if (isSuccess) {
            navigate('/signin');
        }
    };

    const resetPassword = async (e) => {
        e.preventDefault();

        if (!appNumber) {
            console.error('AppNumber manquant dans Redux');
            setMessage('Erreur interne : appNumber non défini');
            setShowModal(true);
            return;
        }
    
        if (!appNumber) {
            console.error('AppNumber non défini dans le store Redux');
            setMessage('Erreur interne : appNumber non défini');
            setShowModal(true);
            return;
        }
    
        if (password !== confirmPassword) {
            setMessage('Les mots de passe ne correspondent pas');
            setShowModal(true);
            return;
        }
    
        if (!regexPassword.test(password)) {
            setMessage('Le mot de passe doit contenir au moins 8 caractères, dont une majuscule, une minuscule, un chiffre et un caractère spécial.');
            setShowModal(true);
            return;
        }
    
        try {
            const response = await fetch(apiPrefix + `reset/${token}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ appNumber, password }),
            });
            
    
            const data = await response.json();
    
            if (response.ok) {
                setIsSuccess(true);
            }
    
            setMessage(data.message);
            setShowModal(true);
        } catch (error) {
            console.error('Erreur lors de la réinitialisation du mot de passe :', error);
            setMessage('Une erreur est survenue lors de la réinitialisation du mot de passe.');
            setShowModal(true);
        }
    };
    

    return (
        <div className='newpass-container'>
            <h2>Réinitialiser le mot de passe</h2>
            <form onSubmit={resetPassword}>
                <div className='password-input'>
                    <input type={showPassword ? 'text' : 'password'} placeholder="Nouveau mot de passe" value={password} onChange={e => setPassword(e.target.value)} required />
                    <HidePasswordIcon showPassword={showPassword} setShowPassword={setShowPassword} />
                </div>
                <input type={showPassword ? 'text' : 'password'} placeholder="Confirmer le nouveau mot de passe" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
                <button type="submit">Réinitialiser le mot de passe</button>
            </form>
            {showModal && (
                <Modal contentModal={message} hide={hide} />
            )}
        </div>
    );
};

export default ResetPassword;

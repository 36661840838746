import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    appNumber: null, // Stocke le numéro de l'app
  },
  reducers: {
    setAppNumber: (state, action) => {
      state.appNumber = action.payload; // Définir le numéro de l'application
    },
  },
});

// Action pour définir le numéro de l'application
export const { setAppNumber } = appSlice.actions;

// Sélecteur pour obtenir le numéro de l'application depuis le store
export const selectAppNumber = (state) => state.app.appNumber;

export default appSlice.reducer;

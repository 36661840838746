import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

/**
 * Footer est un composant qui affiche le pied de page de l'application.
 * Il contient des informations de contact, un logo et des liens vers les pages "Mentions légales" et "CGU".
 *
 */

const Footer = () => {
    const year = new Date().getFullYear();

    // Définir les états pour les éléments dynamiques
    const [logo, setLogo] = useState("");
    const [footerText, setFooterText] = useState("");
    const [mentionsLegales, setMentionsLegales] = useState("");
    const [cguLink, setCguLink] = useState("");
    const [cgvLink, setCgvLink] = useState("");

  
    useEffect(() => {
        // Déterminer l'application courante via le sous-domaine
        const hostname = window.location.hostname;
    
        if (hostname.includes("mesbonsplans.klesiamut")) {
            setLogo(require("../asset/logoKlesiaMPutBlanc.webp")); // Exemple de logo pour app2
            setFooterText("© " + year + " par Mes bons plans Klesia Mut. Tous droits réservés.");
            setMentionsLegales("/MentionsLegalesApp2.docx");
            setCguLink("/CGUApp2.docx");
            setCgvLink("/CGVApp2.docx");
        } else if (hostname.includes("mesbonsplans.carcept-prev")) {
            setLogo(require("../asset/logoBlancCarceptPrev.webp")); // Exemple de logo pour app3
            setFooterText("© " + year + " par Mes bons plans Klesia Carcept. Tous droits réservés.");
            setMentionsLegales("/MentionsLegalesApp3.docx");
            setCguLink("/CGUApp3.docx");
            setCgvLink("/CGVApp3.docx");
        } else if (hostname.includes("mesbonsplans.klesia")) {
            setLogo(require("../asset/logoKlesiaBlanc.webp")); // Exemple de logo pour app1
            setFooterText("© " + year + " par Mes bons plans Klesia Pro. Tous droits réservés.");
            setMentionsLegales("/MentionsLegalesApp1.docx");
            setCguLink("/CGUApp1.docx");
            setCgvLink("/CGVApp1.docx");
        }
    }, [year]);
    

    return (
        <div className='Footer'>
            <div className='footer-section logo-section'>
                <img src={logo} alt="LogoFooter" />
            </div>
            <div className='footer-section'>
                <ul>
                    <li>
                        <NavLink to="/Profil">
                            <i className="fas fa-user"></i> Mon compte
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/ContactForm">
                            <i className="fas fa-pencil-alt"></i> Référencer une marque
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/ContactForm">
                            <i className="fas fa-envelope"></i> Contact
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className='footer-section'>
                <ul>
                    <li>
                        <a href={mentionsLegales} download='MentionsLegales.docx'>
                            <i className="fas fa-file-alt"></i> Mentions légales
                        </a>
                    </li>
                    <li>
                        <a href={cguLink} download='CGU.docx'>
                            <i className="fas fa-file-alt"></i> CGU
                        </a>
                    </li>
                    <li>
                        <a href={cgvLink} download='CGV.docx'>
                            <i className="fas fa-file-alt"></i> CGV
                        </a>
                    </li>
                </ul>
            </div>
            <div className='footer-bottom'>
                <p>{footerText}</p>
            </div>
        </div>
    );
};

export default Footer;

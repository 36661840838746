import React, {  useState } from 'react';
import { useSelector} from 'react-redux';
import Modal from 'fast-modal-library';


/**
 * @file
 * @module Components/AdminCreateUser
 * @description AdminCreateUser est un composant permettant à un administrateur de créer un utilisateur.
 * 
 */




const apiPrefix = process.env.REACT_APP_API_PREFIX;

export default function AdminCreateUser() {
    const Numberapp = useSelector((state) => state.app.appNumber);
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [siret, setSiret] = useState('');
    const [chefEntreprise, setChefEntreprise] = useState(false);
    const [active, setActive] = useState(true);
    const [error, setError] = useState(null);
    const [usernameError, setUsernameError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const regexEmail = /^[^@\s]{2,30}@[^\s@]{2,30}\.[^@\s]{2,}$/;

    const hide = () => {
        setShowModal(false);
        window.location.reload();
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        if (!regexEmail.test(e.target.value)) {
            setUsernameError("Le nom d'utilisateur doit être une adresse e-mail valide.");
        } else {
            setUsernameError('');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!regexEmail.test(username)) {
            setError("Le nom d'utilisateur doit être une adresse e-mail valide.");
            return;
        }

        const [password] = 'p';

        const formData = {
            username,
            firstname,
            password,
            lastname,
            company,
            siret,
            chefEntreprise,
            active
        };
//console.log(formData);

        try {
            const response = await fetch(apiPrefix+'register', {
                method: 'POST',
                headers: {

                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                appNumber: Numberapp
                })
            });

            if (response.ok) {
                console.log('Inscription réussie !');
                setShowModal(true);
                setIsModalOpen(false);
            } else if (response.status === 502) {
                setError("Un compte existe déjà avec cette adresse e-mail.");
            }
             else {
                console.error('Erreur lors de l\'inscription:', response.statusText);
            }
        } catch (error) {
            console.error('Erreur lors de la tentative d\'inscription:', error);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            setIsModalOpen(false);
        }
    };

    return (
        <>

        <button onClick={() => setIsModalOpen(true)}>Créer un utilisateur</button>

{isModalOpen && (
    <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-content">

            <button className="close-button" onClick={() => setIsModalOpen(false)}>X</button>
            <h2>Créer un utilisateur</h2>
            <form onSubmit={handleSubmit}>
                {error && <div className="error-message">{error}</div>}
                <div className="form-group">
                    <label htmlFor="username">Email :</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                    />
                    {usernameError && <div className="error-message">{usernameError}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="firstname">Prénom :</label>
                    <input
                        type="text"
                        id="firstname"
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="lastname">Nom de famille :</label>
                    <input
                        type="text"
                        id="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="company">Entreprise :</label>
                    <input
                        type="text"
                        id="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="siret">SIRET :</label>
                    <input
                        type="text"
                        id="siret"
                        value={siret}
                        onChange={(e) => setSiret(e.target.value)}
                    />
                </div>
                
                <div className="form-group">
                    <label htmlFor="chefEntreprise">Chef d'entreprise ?</label>
                    <select
                        id="chefEntreprise"
                        value={chefEntreprise}
                        onChange={(e) => setChefEntreprise(e.target.value === 'true')}
                    >
                        <option value="false">non</option>
                        <option value="true">oui</option>
                    </select>
                </div>
            
                <div className="form-group">
                    <label htmlFor="active">Actif ?</label>
                    <select
                        id="active"
                        value={active}
                        onChange={(e) => setActive(e.target.value === 'true')}
                    >
                        <option value="false">non</option>
                        <option value="true">oui</option>
                    </select>
                </div>
                <button type="submit">Inscrire</button>
            </form>

        </div>
    </div>)}            {showModal && (
                <Modal contentModal="Inscription réussie !" hide={hide} />
            )}
            </>)
        ;
};
import React, { useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import Modal from 'fast-modal-library';
import { useSelector } from 'react-redux';

/**
 * ImportCsv est un composant qui permet d'importer des données à partir d'un fichier CSV.
 * Il utilise une requête HTTP POST pour envoyer les données du fichier CSV à une API locale.
 * Il utilise les bibliothèques PapaParse et XLSX pour lire les fichiers CSV et Excel.
 * Il utilise le composant Modal pour afficher un message de confirmation après l'importation des données.
 * 
 *
 */
const apiPrefix = process.env.REACT_APP_API_PREFIX;


const ImportCsvExcel = () => {
    const Numberapp = useSelector((state) => state.app.appNumber);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const hide = () => {
        setShowModal(false);
        setData([]);
        window.location.reload();
    };


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (evt) => {
// CSV file
if (file.type === 'text/csv') {
    Papa.parse(evt.target.result, {
        complete: (results) => {
            const nonEmptyRows = results.data.filter(row => row.some(item => item));
            setData(oldData => [...oldData, ...nonEmptyRows]);
        },
    });
}

// Excel file
else if (file.type.includes('spreadsheetml')) {
    const workbook = XLSX.read(evt.target.result, { type: 'binary' });
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const nonEmptyRows = jsonData.filter(row => row.some(item => item));
    setData(oldData => [...oldData, ...nonEmptyRows]);
}
        };

        reader.readAsBinaryString(file);
    };
console.log(data);

    const handleRegisterUsers = async () => {
        for (let i = 1; i < data.length; i++) {
            const row = data[i];
            const [username, password, firstname, lastname, company, siret] = row;
    
            const formData = {
                username,
                password,
                firstname,
                lastname,
                company,
                siret
            };

            try {
                // URL DEV 
                //   const response = await fetch('http://localhost:3001/register', {
                // URL PROD 
                const response = await fetch(apiPrefix+'register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...formData,
                    appNumber: Numberapp
                    })
                });

                if (!response.ok) {
                    console.error('Erreur lors de l\'inscription:', response.statusText);
                }
            } catch (error) {
                console.error('Erreur lors de la tentative d\'inscription:', error);
            }
        }
        setShowModal(true); // Affiche la modale après l'inscription de tous les utilisateurs

    };

    return (
        <div className='importCsv-container'>
            <input type="file" accept=".csv, .xlsx" onChange={handleFileUpload} />
            {data.length > 0 && (
                <>
            <button onClick={handleRegisterUsers}>Inscrire les utilisateurs</button>
            <div className='table-container'>
            <table>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((item, itemIndex) => (
                                <td key={itemIndex}>{item}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
                </>    )}
            {showModal && (
                <Modal contentModal="Inscription réussie !" hide={hide} />
            )}
        </div>
    );
};

export default ImportCsvExcel;

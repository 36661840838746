import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from 'fast-modal-library';

const apiPrefix = process.env.REACT_APP_API_PREFIX;

const ForgotPassword = () => {
    const [username, setUsername] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const appNumber = useSelector((state) => state.app.appNumber);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js?render=6Lfz-I8qAAAAAKUsl8O_nRL6SByk9BRWb8JPstGl';
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            console.log('reCAPTCHA script loaded');
        };

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const hide = () => {
        setShowModal(false);
        navigate('/signin');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!appNumber) {
            console.error('AppNumber non défini dans le store Redux');
            setMessage('Erreur interne : appNumber non défini');
            setShowModal(true);
            return;
        }
    
        if (window.grecaptcha && window.grecaptcha.enterprise) {
            window.grecaptcha.enterprise.ready(async () => {
                try {
                    const token = await window.grecaptcha.enterprise.execute('6Lfz-I8qAAAAAKUsl8O_nRL6SByk9BRWb8JPstGl', { action: 'LOGIN' });
    
                    console.log('Generated reCAPTCHA token:', token);
                    console.log('Sending forgot password request with appNumber:', appNumber);
    
                    const response = await fetch(apiPrefix + 'forgot-password', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ username, token, appNumber })
                    });
    
                    const data = await response.json();
    
                    if (response.ok) {
                        setMessage("Vous allez recevoir un mail vous indiquant la marche à suivre");
                    } else {
                        setMessage(data.error || 'Une erreur est survenue');
                    }
                    setShowModal(true);
                } catch (error) {
                    console.error('Erreur lors de la requête forgot-password:', error);
                    setMessage('Une erreur est survenue lors de la réinitialisation du mot de passe.');
                    setShowModal(true);
                }
            });
        } else {
            setMessage('reCAPTCHA not ready or not available');
            setShowModal(true);
        }
    };
    

    return (
        <div className='forgotpass-container'>
            <h2>Réinitialiser le mot de passe</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Nom d'utilisateur"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    required
                />
                <button type="submit">Envoyer l'e-mail de réinitialisation</button>
            </form>
            {message && <p className='error-message'>{message}</p>}
            {showModal && (
                <Modal contentModal={message} hide={hide} />
            )}
        </div>
    );
};

export default ForgotPassword;

import '@fortawesome/react-fontawesome';


export default function HidePasswordIcon({ showPassword, setShowPassword }) {


//afficher ou masquer le mot de passe
const handleShowPassword = () => {
    setShowPassword(!showPassword);
}


    return (

    <button onClick={handleShowPassword} id='HideShowPassword' type='button'>
{            showPassword === false ?
    <i className="fa-regular fa-eye"></i>
    :
    <i className="fa-regular fa-eye-slash"></i>}
    </button >
)
}
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Modal from 'fast-modal-library';

/**
 * AdminUser est une page qui affiche les détails d'un utilisateur spécifique.
 * Elle utilise le hook useParams de 'react-router-dom' pour obtenir l'ID de l'utilisateur à partir de l'URL.
 * Elle utilise ensuite l'ID pour faire une requête HTTP GET à l'API et obtenir les détails de l'utilisateur.
 * Elle affiche ensuite ces détails à l'administrateur.
 * Elle fournit également un formulaire pour mettre à jour les détails de l'utilisateur, qui fait une requête HTTP PUT à l'API.
 * Elle fournit également une fonction pour supprimer l'utilisateur, qui fait une requête HTTP DELETE à l'API.
 * Cette page utilise le hook useState de React pour gérer l'état de l'utilisateur.
 * Cette page utilise le hook useEffect de React pour appeler l'API lors du chargement de la page.
 * Cette page utilise le hook useSelector de 'react-redux' pour accéder au token de l'utilisateur dans le store Redux.
 * Cette page utilise le hook useNavigate de 'react-router-dom' pour naviguer vers une autre page après la suppression de l'utilisateur.
 * 
 */
const apiPrefix = process.env.REACT_APP_API_PREFIX;

const UserDetail = () => {
    const Numberapp = useSelector((state) => state.app.appNumber);
    const { id } = useParams(); 
    const [user, setUser] = useState(null); 
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); 
    const token = useSelector((state) => state.user.token);

    const hide = () => {
        setShowModal(false);
        navigate('/admin/users'); 
    };

    useEffect(() => {
        const fetchUserDetails = async () => {
          try {
            const response = await fetch(apiPrefix + `users/${id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'X-App-Number': Numberapp // Ajout de l'en-tête personnalisé pour l'application
              },
            });
      
            if (!response.ok) {
              throw new Error('Erreur lors de la récupération des détails de l\'utilisateur');
            }
      
            const data = await response.json();
            setUser(data); // Mettre à jour l'état de l'utilisateur avec les données récupérées
          } catch (error) {
            console.error('Erreur lors de la récupération des détails de l\'utilisateur', error);
          }
        };
      
        fetchUserDetails();
      }, [id, apiPrefix, Numberapp]); // Ajout de Numberapp aux dépendances
      

    const handleDelete = () => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
            // Supprimer l'utilisateur
            // URL DEV
            //fetch(`http://localhost:3001/users/${id}`, {
            // URL PROD
            fetch(apiPrefix +`users/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`, 
                    'X-App-Number': Numberapp 
                },

            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la suppression de l\'utilisateur');
                }
                navigate('/'); 
            })
            .catch(error => {
                console.error('Erreur lors de la suppression de l\'utilisateur', error);
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    console.log(user)
        // Mettre à jour l'utilisateur
        //URL DEV
        //  fetch(`http://localhost:3001/admin/update/${id}`, {
        // URL PROD
        fetch(apiPrefix +`admin/update/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, 
            },
            body: JSON.stringify({
                ...user,
                appNumber: Numberapp

        }),
        })
        .then(response => response.json())
        .then(data => {
            setMessage('Profil mis à jour avec succès');
            setShowModal(true);
        })
        .catch(error => {
            console.error('Erreur lors de la mise à jour du profil', error);
        });
    };

    if (!user) {
        return <div>Chargement...</div>; 
    }

    const handleChange = (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setUser({ ...user, [event.target.name]: value });
    };
    
    return (
        <div className="center-container">
              <Link to="/admin/users">Retour à la page des utilisateurs</Link>

            <h1>{user.username}</h1>
            <div className="activity-container">
            {user.lastactive === null ? <td> pas d'activité</td> : 
                <td>Derniere activité: {new Date(user.lastactive).toLocaleString()}</td>}
            </div>
            <form onSubmit={handleSubmit}>
                <label>
                    Nom d'utilisateur:
                    <input type="text" name="username" value={user.username} onChange={handleChange} />
                </label>
                <label>
                    Prénom:
                    <input type="text" name="firstname" value={user.firstname} onChange={handleChange} />
                </label>
                <label>
                    Nom:
                    <input type="text" name="lastname" value={user.lastname} onChange={handleChange} />
                </label>
                <label>
                    Société:
                    <input type="text" name="company" value={user.company} onChange={handleChange} />
                </label>
                <label>
                    Siret:
                    <input type="text" name="siret" value={user.siret} onChange={handleChange} />
                </label>
                
<label>
    Actif:
    <input type="checkbox" name="active"  className="check-box" checked={user.active} onChange={handleChange} />
</label>
<label>
    Admin:
    <input type="checkbox" name="is_admin" className="check-box" checked={user.is_admin} onChange={handleChange} />
</label>
<label>
    Chef d'entreprise:
    <input type="checkbox" name="chefEntreprise" className="check-box" checked={user.chefEntreprise} onChange={handleChange} />
</label>
<div className="button-container">
        <button type="submit">Mettre à jour</button>
        {showModal && (
                <Modal contentModal={message} hide={hide} />)}
        <button type="button" onClick={handleDelete}>Supprimer</button>
    </div>
            </form>
              
        </div>
    );
};

export default UserDetail;

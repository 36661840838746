
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logout from '../components/LogOut'; 

/**
 * Header est un composant qui affiche le logo et la barre de navigation en haut de l'application.
 * Il contient des liens de navigation vers différentes pages de l'application.
 * Il utilise le composant NavLink de 'react-router-dom' pour créer ces liens.
 * Il utilise le hook useSelector de 'react-redux' pour accéder à l'état de l'utilisateur dans le store Redux.
 */

const Header = () => {
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);

    // États pour le logo et le texte dynamiques
    const [logo, setLogo] = useState("");
    const [appTitle, setAppTitle] = useState("");

    useEffect(() => {
        const hostname = window.location.hostname;
    
        console.log("Hostname détecté :", hostname);
    
        if (hostname.includes("mesbonsplans.klesiamut")) {
            console.log("App 2 détectée");
            setLogo(require("../asset/logoKlesiaMut.webp"));
            setAppTitle("Mes Bons Plans - App 2");
        } else if (hostname.includes("mesbonsplans.klesia")) {
            console.log("App 1 détectée");
            setLogo(require("../asset/logoKlesia.webp"));
            setAppTitle("Mes Bons Plans - App 1");
        } else if (hostname.includes("mesbonsplans.carcept-prev")) {
            console.log("App 3 détectée");
            setLogo(require("../asset/KLESIACarceptLogo.webp"));
            setAppTitle("Mes Bons Plans - App 3");
        } else {
            console.log("App par défaut détectée");
            setLogo(require("../asset/logoKlesia.webp"));
            setAppTitle("Mes Bons Plans - Default");
        }
    }, []);
    

    return (
        <header className='Header'>
            <NavLink to="/" >
                <img src={logo} className="logo" alt="App Logo" />
            </NavLink>
            <div className="app-title">
                <h1>{appTitle}</h1> {/* Titre de l'app en fonction du sous-domaine */}
            </div>
            <div className='navigation'>
                {isLoggedIn ? (
                    <>
                        <NavLink to={"/"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Accueil</NavLink>
                        <NavLink to={"/Profil"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Mon profil</NavLink>
                        <Logout /> {/* Utiliser le composant Logout */}
                    </>
                ) : (
                    <>
                        <NavLink to={"/Signin"} className={(nav) => (nav.isActive ? 'nav-active' : 'nav')}>Se connecter</NavLink>
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const AutoRedirect = ({ redirectUrl }) => {
    const apiPrefix = process.env.REACT_APP_API_PREFIX;
    const Numberapp = useSelector((state) => state.app.appNumber);
    const [error, setError] = useState(null);
    const userId = useSelector((state) => state.user.user.user.id);

    const fetchToken = async () => {
        try {
            if (!Numberapp) {
                throw new Error('Invalid application number');
            }
            const response = await fetch(`${apiPrefix}autologin/token/${userId}?appNumber=${Numberapp}`, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
            const data = await response.json();
            return data.token;
        } catch (error) {
            setError(error.message);
            return null;
        }
    };

    const handleRedirect = async () => {
        try {
            const token = await fetchToken();
            if (token) {
                const redirectLink = redirectUrl.includes('app.reducce.fr')
                    ? `${redirectUrl}${token}?organisation=CA-CEST-POUR-MOI`
                    : `${redirectUrl}${token}`;
                window.location.href = redirectLink;
            }
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div>
            {error && <p className="error">Error: {error}</p>}
            <button className='cta-button' onClick={handleRedirect}>Je découvre !</button>
        </div>
    );
};

export default AutoRedirect;

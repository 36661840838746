import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Error from "./pages/Error";
import Header from "./components/Header";
import Footer from "./components/Footer";
import IsAdminHeader from "./components/IsAdminHeader";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Profile from "./pages/Profile";
import AdminUsers from "./pages/AdminUsers";
import UserDetail from "./pages/AdminUser";
import ForgotPassword from "./pages/ForgotPass";
import ResetPassword from "./pages/GenerateNewPassword";
import AdminCreateSignupPage from "./pages/AdminCreateSignupPage";
import ContactForm from "./pages/ContactForm";
import { checkTokenExpiration, selectToken } from './feature/user.Slice';
import { setAppNumber, selectAppNumber } from './feature/app.Slice';

/**
 * @file
 * @summary App est le composant racine de l'application.
 * @description Il contient le routeur qui définit les différentes routes de l'application.
 * 
 * ## Routes
 * Les routes sont les suivantes :
 * - **"/"** : la page d'accueil, accessible à tous les utilisateurs.
 * - **"/admin/users"** : la page d'administration des utilisateurs, accessible uniquement aux utilisateurs administrateurs.
 * - **"/admin/CreateSignupPage"** : la page de création de la page d'inscription, accessible uniquement aux utilisateurs administrateurs.
 * - **"/admin/users/:id"** : la page de détail de l'utilisateur, accessible uniquement aux utilisateurs administrateurs.
 * - **"/admin"** : la page d'administration, accessible uniquement aux utilisateurs administrateurs.
 * - **"/forgot"** : la page de réinitialisation du mot de passe, accessible à tous les utilisateurs.
 * - **"/reset/:token"** : la page de réinitialisation du mot de passe avec le token, accessible à tous les utilisateurs.
 * - **"/Mentions"** : la page des mentions légales, accessible à tous les utilisateurs.
 * - **"/Cgu"** : la page des conditions générales d'utilisation, accessible à tous les utilisateurs.
 * - **"/Profile"** : la page de profil, accessible uniquement aux utilisateurs connectés.
 * - **"/signin"** : la page de connexion, accessible uniquement aux utilisateurs non connectés.
 * - **"/signup/:encryptedEntreprise/:encryptedSiret"** : la page d'inscription, accessible uniquement aux utilisateurs non connectés. Les informations de l'entreprise et du SIRET peuvent être pré-remplies à partir de l'URL si elles sont fournies sous forme cryptée.
 * - **"/dashboard"** : la page de tableau de bord, accessible uniquement aux utilisateurs administrateurs.
 * - **"/error"** : la page d'erreur, accessible à tous les utilisateurs.
 * - **"*"** : la page d'erreur, accessible à tous les utilisateurs.
 * 
 * ## Redirections
 * - Si un utilisateur non connecté tente d'accéder à une page réservée aux utilisateurs connectés, il est redirigé vers la page de connexion.
 * - Si un utilisateur connecté tente d'accéder à la page de connexion ou à la page d'inscription, il est redirigé vers la page d'accueil.
 * - Si un utilisateur non administrateur tente d'accéder à une page réservée aux administrateurs, il est redirigé vers la page de connexion.
 */

function App() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user.user && state.user.user.user);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  // Pour gérer le style à charger dynamiquement
  const [loadedStyles, setLoadedStyles] = useState(false);

  useEffect(() => {
    dispatch(checkTokenExpiration());
  }, [dispatch, token]);

  useEffect(() => {
    // Déterminer le style à charger selon le sous-domaine
    const hostname = window.location.hostname;

    const loadStyleForApp = async () => {
      let appNumber = null;
  
      if (hostname.includes("mesbonsplans.klesiamut")) {
          await import("./app/styles/app2/_index.scss"); // Charger les styles de app2
          appNumber = 2;
      } else if (hostname.includes("mesbonsplans.carcept-prev")) {
          await import("./app/styles/app3/_index.scss"); // Charger les styles de app3
          appNumber = 3;
      } else if (hostname.includes("mesbonsplans.klesia")) {
          await import("./app/styles/app1/_index.scss"); // Charger les styles de app1
          appNumber = 1;
      } else {
          console.warn("Sous-domaine non reconnu, chargement par défaut.");
          await import("./app/styles/app3/_index.scss"); // Charger les styles de app3 par défaut
          appNumber = 3;
      }
  
      if (appNumber !== null) {
          dispatch(setAppNumber(appNumber));
      }
  
      setLoadedStyles(true); // Indiquer que les styles ont été chargés
  };
  

    loadStyleForApp();
    
  }, [dispatch]);
  const appNumber = useSelector(selectAppNumber);
  useEffect(() => {
    console.log("Le numero de l'application est :", appNumber);
  }, [appNumber])
  
  // Ne rien rendre tant que les styles ne sont pas chargés
  if (!loadedStyles) {
    return null;
  }

  return (
    <>
      {isLoggedIn && <IsAdminHeader />}
      <Header />
      <Routes>
        <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/signin" />} />
        <Route path="/admin/users" element={user && user.is_admin ? <AdminUsers /> : <Navigate to="/signin" />} />
        <Route path="/admin/CreateSignupPage" element={user && user.is_admin ? <AdminCreateSignupPage /> : <Navigate to="/signin" />} />
        <Route path="/admin/users/:id" element={isLoggedIn && user.is_admin ? <UserDetail /> : <Navigate to="/signin" />} />
        <Route path="/admin" element={isLoggedIn && user.is_admin ? <AdminUsers /> : <Navigate to="/signin" />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPassword />} />
        <Route path="/Profil" element={isLoggedIn ? <Profile /> : <Navigate to="/signin" />} />
        <Route path="/ContactForm" element={isLoggedIn ? <ContactForm /> : <Navigate to="/signin" />} />
        <Route path="/signin" element={isLoggedIn ? <Navigate to="/" /> : <Signin />} />
        <Route path="/signup/:encryptedEntreprise/:encryptedSiret" element={isLoggedIn ? <Navigate to="/" /> : <Signup />} />
        <Route path="/dashboard" element={isLoggedIn && user.is_admin ? <Dashboard /> : <Navigate to="/signin" />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
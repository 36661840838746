import{ useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


export default function Stats() {
  const Numberapp = useSelector((state) => state.app.appNumber);
  const token = useSelector((state) => state.user.token);
    const apiPrefix = process.env.REACT_APP_API_PREFIX;
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const response = await fetch(apiPrefix + 'users', {
            method: 'GET', 
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
              'X-App-Number': Numberapp // Envoyer appNumber via l'en-tête
            },
          });
  
          if (!response.ok) {
            throw new Error('Erreur lors de la récupération des utilisateurs');
          }
  
          const data = await response.json();
          setUsers(data);
          setIsLoading(false);
        } catch (error) {
          console.error("Erreur lors de la récupération des utilisateurs :", error);
          setError(error.message);
          setIsLoading(false);
        }
      };
  
      fetchUsers();
    }, [apiPrefix, Numberapp, token]);
      
const date = new Date();
const currentMonth = date.getMonth();
const currentYear = date.getFullYear();



const userConnectedThisMonth = users.filter((user) => {
    const lastActive = new Date(user.lastactive);
    return lastActive.getMonth() === currentMonth && lastActive.getFullYear() === currentYear;
})


const userCreatedThisMonth = users.filter((user) => {
    const creationDate = new Date(user.creationDate);
    return creationDate.getMonth() === currentMonth && creationDate.getFullYear() === currentYear;
})

const createdActiveThisMonth = users.filter((user) => {
    const creationDate = new Date(user.creationDate);
    const lastActive = new Date(user.lastactive);
    return creationDate.getMonth() === currentMonth && creationDate.getFullYear() === currentYear && lastActive.getMonth() === currentMonth && lastActive.getFullYear() === currentYear;
})

console.log(createdActiveThisMonth.length);




      return (
        <table className='stats-table'>
        <thead>
          <tr>
            <th>Inscription / Mois</th>
            <th>Inscription / Connexion / Mois</th>
            <th>Connexion / Mois</th>
          </tr>
        </thead>
        <tbody>
            <tr>
                <td>{userCreatedThisMonth.length}</td>
                <td>{createdActiveThisMonth.length}</td>
                <td>{userConnectedThisMonth.length}</td>
            </tr>
        </tbody>
        </table>
      );
}